import React from 'react'
import Check from '~/components/icons/Check'
import Typography, { type TypographyProps } from '~/components/Typography'
import { cn } from '~/utils/cn'
import * as styles from './Checkbox.styles'

export type CheckboxProps = PropsWithClassName<{
  label?: React.ReactNode
  labelProps?: TypographyProps<'span'>
  htmlFor?: string
  isError?: boolean
}> &
  styles.CheckboxVariants &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>

const Checkbox = React.forwardRef(
  (
    {
      label,
      labelProps,
      htmlFor = '',
      size = 'md',
      isError = false,
      alignVertical = 'center',
      ...restInputProps
    }: CheckboxProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const forFallback = React.useId()
    const forAttr = htmlFor || forFallback
    const { className, ...restLabelProps } = labelProps ?? {}
    const { square, base } = styles.checkboxVariants({ size })

    return (
      <label htmlFor={forAttr} className={base({ alignVertical })}>
        <div className={square({ size })}>
          <input
            type="checkbox"
            id={forAttr}
            aria-invalid={isError}
            className="absolute h-0 w-0 opacity-0"
            {...restInputProps}
            ref={ref}
          />
          <Check />
        </div>
        {label ? (
          <div>
            <Typography
              className={cn('leading-snug text-black/50', className)}
              color={isError ? 'error' : undefined}
              component="span"
              variant={size === 'sm' ? 'subtitle2' : 'body2'}
              {...restLabelProps}
            >
              {label}
            </Typography>
          </div>
        ) : null}
      </label>
    )
  }
)

export default Checkbox

import { tv, type VariantProps } from 'tailwind-variants'

export const checkboxVariants = tv(
  {
    slots: {
      base: 'flex cursor-pointer justify-start gap-x-3',
      square:
        'flex aspect-square shrink-0 items-center justify-center rounded-md border-2 border-black/15 bg-white text-white focus-within:border-black has-[:checked]:bg-black'
    },
    variants: {
      alignVertical: {
        start: {
          base: 'items-start'
        },
        center: {
          base: 'items-center'
        }
      },
      size: {
        sm: {
          square: 'w-[20px]'
        },
        md: {
          square: 'w-[24px]'
        }
      }
    }
  },
  {
    twMerge: false
  }
)

export type CheckboxVariants = VariantProps<typeof checkboxVariants>
